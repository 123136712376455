import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import { Component} from "vue-property-decorator";
import Container from 'typedi';
import ErrorHandlerService from "@/modules/common/services/error-handler.service";

@Component({
    name: 'GtrMcProReturn',
})
export default class GtrMcProReturnView extends GtrSuper {
    data() {
        return {
            mcTradeError: false
        }
    }

    async mounted() {
        try {
            const result = await this.$store.dispatch('event/mcTradeReturn', this.$route.params.event_identifier, { root: true })
            localStorage.setItem("mctrade_data", JSON.stringify(result.data))
            this.checkURLAndGo(result.data.return_url)
        } catch (error) {
            this.$data.mcTradeError = true
            Container.get(ErrorHandlerService).error(error)
        }
    }

}
